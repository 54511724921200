<template>
  <div>
    <v-container :key="reloadKey">
      <v-row class="mt-4">
        <h3>Transform Files</h3>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="current"
            :items="pipelines"
            item-text="description"
            item-value="pipeline"
            label="Type of import"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-file-input :disabled="!current"
            accept=".txt,.csv"
            label="Import (.txt,.csv)"
            show-size
            clearable
            @change="onFileSelected"
          >
          </v-file-input>
        </v-col>
      </v-row>
      <v-row v-if="transforming">
        <v-col cols="2">
          <p>{{statusText}}</p>
        </v-col>
        <v-col cols="2">
          <p>{{transformedCount}} records...</p>
        </v-col>
        <v-col cols="8">
          <v-progress-linear
            color="deep-purple accent-4"
            :value="progress"
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-if="reload">
        <v-col>
          <v-btn rounded @click="reloadMe">
            <v-icon class="mr-2">{{icons.mdiReload}}</v-icon> Load another
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <p>Output:</p>
      <pre id="preOutput" class="elevation-5">
      </pre>
    </v-container>
  </div>
</template>

<script>
import {
  mdiReload,
} from '@mdi/js';

import { mapActions } from 'vuex';
import LineNavigator from 'line-navigator';
import { clients } from '../../util/clients';

const initdata = () => ({
  icons: {
    mdiReload,
  },
  current: undefined,
  transforming: false,
  reload: false,
  reloadKey: 0,
  statusText: '',
  progress: 0,
  transformedCount: 0,
  pipelines: [
    {
      description: 'Debug Tmc Jobs Tax Ascii',
      pipeline: {
        type: 'tmc-jobs-tax-ascii',
        action: 'preOutput',
      },
    },
  ],
});

export default {
  name: 'TransformFiles',
  data: () => initdata(),
  methods: {
    ...mapActions([
      'flashError',
      'flashSuccess',
    ]),
    // based on https://github.com/anpur/line-navigator
    onFileSelected(file) {
      const self = this;
      this.transforming = true;
      this.statusText = 'Loading file...';

      // eslint-disable-next-line no-undef
      const navigator = new LineNavigator(file, {
        encoding: 'utf8',
        chunkSize: 1024 * 4,
      });

      let chunkId = 0;
      // function linesReadHandler
      const linesReadHandler = async (err, index, lines, isEof, progress) => {
        if (err) self.flashError(`Chunk Id ${chunkId} => ${err}`);

        self.statusText = `Transforming file (${progress}%) ...`;
        chunkId += 1;

        let response;
        try {
          response = await clients.direct.backendRest.post(
            `/transform?type=${self.current.type}`, lines.join('\r\n'),
          );
        } catch (error) {
          return clients.handleAxiosErrors(error);
        }

        const transforms = response.data;
        self[self.current.action](transforms, chunkId);

        if (!isEof) {
          navigator.readSomeLines(index + lines.length, linesReadHandler);
        } else {
          self.preOutput({ done: '✅ done!' });
          self.flashSuccess('Transformed!');
          self.reload = true;
        }
        return chunkId;
      };
      navigator.readSomeLines(0, linesReadHandler);
    },
    reloadMe() {
      Object.assign(this.$data, initdata());
      this.reloadKey += 1;
    },
    preOutput(transforms) {
      document.getElementById('preOutput').innerText += `${JSON.stringify(transforms)}\n`;
    },
  },
};
</script>
